<template>
  <Dialog
    v-model:visible="displayModal"
    :modal="true"
    :closeOnEscape="true"
    :dismissableMask="true"
    :breakpoints="{'1300px': '100vw'}" :style="{width: '90vw'}"
  >
    <template #header>
      <div style="color:#1c3faa;">
        <h4>
          <i class="pi pi-file" style="font-size: 1.5rem;margin-right:10px"></i>
          <strong style="font-size:20px">Detalles recepción administrativa</strong>
        </h4>
      </div>
    </template>
    <Card class="w-full">
      <template #content>
        <div class="w-full text-xxs">
          <div class="grid grid-cols-1 xl:grid-cols-2 lg:gap-x-10 xl:gap-x-60">
            <div class="grid grid-rows-4 gap-1 pt-3">
              <div class="grid grid-cols-4 items-center">
                <div class="col-span-1">
                  <strong>Código Cliente/Entidad: </strong>
                </div>
                <div class="col-span-3">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="recepcionAdmin.CardCode" disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="col-span-1">
                  <strong>Nombre Cliente/Entidad: </strong>
                </div>
                <div class="col-span-3">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="recepcionAdmin.CardName" disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="col-span-1">
                  <strong>Usuario: </strong>
                </div>
                <div class="col-span-3">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="recepcionAdmin.userName" disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="col-span-1">
                  <strong>Numero de factura: </strong>
                </div>
                <div class="col-span-3">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="recepcionAdmin.NumFactura" disabled/>
                </div>
              </div>
            </div>
            <div class="grid grid-rows-4 gap-1 pt-1">
              <div class="grid grid-cols-4 items-center">
                <div class="xl:col-end-4 col-span-1">
                  <strong>Fecha de documento: </strong>
                </div>
                <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="recepcionAdmin.DocDate" disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="xl:col-end-4 col-span-1">
                  <strong>Fecha de recepción: </strong>
                </div>
                <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="recepcionAdmin.createdAt" disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="xl:col-end-4 col-span-1">
                  <strong>N° Recepción Administrativa: </strong>
                </div>
                <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="recepcionAdmin.id" disabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%" class="mt-6">
          <div>
            <DataTable
              :value="recepcionAdmin.detalle"
              class="p-datatable-sm text-xxs"
              showGridlines
              dataKey="LineNum"
              responsiveLayout="scroll"
              :scrollable="true"
              scrollHeight="500px"
            >
              <template #empty>
                No hay datos para mostrar
              </template>
              <Column field="LineNum" header="Indice pedido" headerStyle="justify-content: center;text-align: center"
                      style="min-width:3rem; max-width: 3rem;"
                      bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>{{ data.LineNum }}</div>
                </template>
              </Column>
              <Column field="ItemCode" header="Artículo" headerStyle="justify-content: center;text-align: center"
                      align="left" style="min-width:32rem">
                <template #body="{data}">
                  <div class="grid grid-cols-1 w-full">
                    <div class="flex justify-between items-center">
                      <div>
                        <strong>Código Mx: </strong>{{ data.ItemCode }}
                      </div>
                      <div class="flex gap-2">
                        <Badge v-if="data.ReqMarca" :value="`Req. marca`"
                               class=" bg-red-300 text-red-700 text-xxs h-4 capitalize"/>
                        <Badge v-if="data.Controlado" value="Controlado"
                               class=" bg-green-300 text-green-700 text-xxs h-4 capitalize"></Badge>
                        <Badge v-if="data.Regulado" value="Regulado"
                               class=" bg-blue-300 text-red-blue text-xxs h-4 capitalize"></Badge>
                        <Badge v-if="data.cadenaFrio" value="Cadena Frio"
                               class="bg-blue-800 text-blue text-xxs h-4"></Badge>
                      </div>
                    </div>
                    <div>
                      <strong>Medicamento Pedido: </strong>{{ data.ItemName }}
                    </div>
                    <div>
                      <strong>Medicamento factura: </strong>{{ data.Description }}
                    </div>
                  </div>
                </template>
              </Column>
<!--              <Column field="DistNumber" header="Lote" headerStyle="justify-content: center;text-align: center"-->
<!--                      style="max-width:8rem"-->
<!--                      bodyStyle="justify-content: center;text-align: center">-->
<!--                <template #body="{data}">-->
<!--                  <div>{{ data.DistNumber }}</div>-->
<!--                </template>-->
<!--              </Column>-->
<!--              <Column field="ExpDate" header="F. Venc." headerStyle="justify-content: center;text-align: center"-->
<!--                      style="max-width:8rem"-->
<!--                      bodyStyle="justify-content: center;text-align: center">-->
<!--                <template #body="{data}">-->
<!--                  <div>{{ data.ExpDate }}</div>-->
<!--                </template>-->
<!--              </Column>-->
              <Column field="BatchNumbers" header="Info. Lotes" headerStyle="justify-content: center;text-align: center"
                      style="max-width:8rem"
                      bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div class="mr-3">
                    <div>
                      <Button type="button" class="p-button-xs" icon="pi pi-eye" label="Ver"
                              @click="toggle($event,data)" aria-haspopup="true"
                              aria-controls="overlay_panel"/>
                      <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel"
                                    style="width: 500px" :breakpoints="{'960px': '65vw'}">
                        <DataTable
                          :value="info"
                          class="p-datatable-sm text-xs"
                          showGridlines
                          dataKey="id"
                          scrollHeight="500px"
                          :scrollable="true"
                          responsiveLayout="scroll"
                        >
                          <Column field="DistNumber" header="Lote" headerStyle="justify-content: center;"
                                  style="min-width:5rem" bodyStyle="text-align: center"/>
                          <Column field="Quantity" header="Cant." headerStyle="justify-content: center;"
                                  style="min-width:2rem" bodyStyle="text-align: center">
                            <template #body="{data}">
                              <span>{{ $h.formatNumber(data.Quantity) }}</span>
                            </template>
                          </Column>
                          <Column field="ExpDate" header="F.Venc."
                                  headerStyle="justify-content: center;"
                                  style="min-width:5rem" bodyStyle="text-align: center"/>
                        </DataTable>
                      </OverlayPanel>
                    </div>
                  </div>
                </template>
              </Column>
              <Column field="sumQuantity" header="Cant. lotes" headerStyle="justify-content: center;text-align: center"
                      style="max-width:5rem"
                      bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>{{ $h.formatNumber(data.Quantity) }}</div>
                </template>
              </Column>
              <Column field="Quantity" header="Cant. factura" headerStyle="justify-content: center;text-align: center"
                      style="max-width:5rem"
                      bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>{{ $h.formatNumber(data.InvoiceQuantity) }}</div>
                </template>
              </Column>
              <Column field="Price" header="Precio" headerStyle="justify-content: center;text-align: center"
                      style="max-width:8rem"
                      bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>{{ $h.formatCurrency(data.Price) }}</div>
                </template>
              </Column>
              <Column field="LineTotal" header="Total linea" headerStyle="justify-content: center;text-align: center"
                      style="max-width:8rem"
                      bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>{{ $h.formatCurrency(data.LineTotal) }}</div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </template>
    </Card>
    <Card class="w-full mt-4">
      <template #content>
        <div class="w-full grid grid-cols-1 lg:grid-cols-3 gap-2 xl:gap-8">
          <div class="grid text-xxs grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-1 gap-2 xl:gap-8 lg:col-span-2">
            <div class="flex flex-col">
              <p class="mb-2">
                <strong>Comentarios: </strong>
              </p>
              <Textarea
                class="p-inputtext-xxs"
                disabled
                v-model="recepcionAdmin.Comments"
                :autoResize="true"
                rows="2"
                cols="40"/>
            </div>
            <div class="flex flex-col">
              <p class="mb-2">
                <strong>Entrada de diario: </strong>
              </p>
              <Textarea
                class="p-inputtext-xxs"
                disabled
                v-model="recepcionAdmin.JournalMemo"
                :autoResize="true"
                rows="2"
                cols="40"
              />
            </div>
          </div>
          <div class="text-xxs lg:col-span-1">
            <div class="flex justify-between border-b-2 pb-3">
              <span class="text-gray-600">Total sin descuento:</span>
              <span class="">{{ $h.formatCurrency(recepcionAdmin.DocTotal - recepcionAdmin.DiscTotal) }}</span>
            </div>
            <div class="flex justify-between my-3">
              <span class="text-gray-600">Total descuento:</span>
              <span class="">{{ $h.formatCurrency(recepcionAdmin.DiscTotal) }}</span>
            </div>
            <div class="flex justify-between my-3">
              <span class="text-gray-600">Total IVA:</span>
              <span class="">{{ $h.formatCurrency(recepcionAdmin.TaxTotal) }}</span>
            </div>
            <div class="flex justify-between bg-blue-800 text-white rounded-lg p-2 text-sm">
              <span class="font-bold">Total:</span>
              <span class="">{{ $h.formatCurrency(recepcionAdmin.DocTotal) }}</span>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </Dialog>
</template>

<script>
import { ref } from 'vue'
// import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'

export default {
  name: 'modalXmlDetallado',
  setup () {
    const displayModal = ref(false)
    const recepcionAdmin = ref({})
    const op = ref()
    const info = ref([])
    // const _RecepcionPedidosService = new RecepcionPedidosService()
    const openModal = (values) => {
      recepcionAdmin.value = values
      displayModal.value = true
    }
    const toggle = (event, data) => {
      info.value = data.BatchNumbers
      op.value.toggle(event)
    }
    return {
      openModal,
      displayModal,
      recepcionAdmin,
      toggle,
      info,
      op
    }
  }
}
</script>

<style scoped>
.header {
  display: grid;
  box-shadow: 3px 3px 3px 4px whitesmoke;
}

.inputsHeader {
  background-color: #eef1f5;
  width: 100%;
  height: 100%;
}

.footerModal {
  display: flex;
  /*justify-content: space-between;*/
  padding: 10px;
}

::v-deep(.pi) {
  font-size: 0.8rem;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}

::v-deep(.p-card-content) {
  padding: 0rem;
}

::v-deep(.lineaCerrada) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
</style>
